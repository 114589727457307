.link,
.link:visited,
.link:active {
  color: var(--brand-dark-green);
  text-decoration: underline;
}

.link:hover {
  color: var(--brand-dark-red);
}

.bidRow {
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
}

.currentBidCol {
  border-right: 0 !important;
}

.text {
  font-family: 'PT Root UI', sans-serif;
  padding: 0.25rem 0.75rem 0.5rem 0.75rem;
  color: var(--brand-warm-light-text);
}

.sunouns {
  color: var(--brand-dark-red);
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition-duration: 1.5s;

}

.sunouns:hover {
  display: inline-block;
  color: var(--brand-sunrise-blue);
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}
