.wrapper {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 12px 0px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.icon {
  margin-right: 0.4rem;
}

.icon > svg {
  max-height: 14px;
  opacity: 0.5;
}

@media (max-width: 992px) {
  .icon > svg {
    max-height: 17px;
  }
}

.button:hover {
  cursor: pointer;
}

.coolInfo {
  border: 1px solid var(--brand-dark-red);
  border-radius: 10px;
  color: var(--brand-dark-red);
}

.coolInfo:hover {
  background-color: var(--brand-dark-red);
  color: black;
}

.coolWallet {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid var(--brand-dark-red);
  border-radius: 10px;
  color: var(--brand-dark-red);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
}

.coolWallet:hover {
  background-color: var(--brand-dark-red);
  color: var(--brand-su-black);
  filter: brightness(110%);
}

.warmInfo {
  border: 1px solid var(--brand-dark-red);
  border-radius: 10px;
  color: var(--brand-dark-red);
}

.warmInfo:hover {
  background-color: var(--brand-warm-accent);
  color: black;
}

.warmWallet {
  background-color: var(--brand-su-black);
  border: 1px solid var(--brand-dark-red);
  border-radius: 10px;
  color: var(--brand-dark-red);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
}

.warmWallet:hover {
  background-color: var(--brand-dark-red);
  color: var(--brand-su-black);
  filter: brightness(110%);
}

.whiteInfo,
.whiteWallet {
  background-color: var(--brand-su-black);
  border: 1px solid var(--brand-dark-red);
  border-radius: 10px;
  color: var(--brand-dark-red);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
}

.whiteInfo:hover,
.whiteWallet:hover {
  background-color: var(--brand-dark-red);
  color: black;
  filter: brightness(110%);
}

.whiteActive {
  background-color: #f4f4f8;
  color: black;
}

.whiteActive .icon {
  color: #d63c5e;
}

.whiteActiveVoteSubmit {
  background-color: #e2e3e8;
  color: black;
}

@media (max-width: 992px) {
  .wrapper,
  .button {
    height: 48px;
    font-size: 18px;
  }
}
