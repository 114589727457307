@media (max-width: 992px) {
  .emergencySettleWrapper {
    text-align: center;
    margin-left: 0.5rem;
  }
}

@media (max-width: 660px) {
  .emergencySettleWrapper {
    text-align: center;
    margin-left: 0rem;
  }
}

.emergencySettleButton {
  border: 1px solid var(--brand-dark-red);
  color: var(--brand-dark-red);
  background-color: transparent;
  border-radius: 10px;
  text-decoration: none;
  display: inline;
  padding: 20px;
}
.emergencySettleButton:hover {
  border: 1px solid var(--brand-dark-red);
  color: var(--brand-su-black);
  background-color: var(--brand-dark-red);
  border-radius: 10px;
  text-decoration: none;
  display: inline;
  padding: 20px;
}
.emergencySettleButton:disabled {
  color: var(--brand-dark-red);
  text-decoration: none;
  cursor: default;
}
.emergencySettleButton:disabled:hover {
  color: var(--brand-su-black);
  text-decoration: none;
  cursor: default;
}
