.undergroundPage h1,
.undergroundPage h2,
.undergroundPage h3 {
  font-family: 'Londrina Solid';
  color: var(--brand-dark-red);
}

.headerRow span {
  color: #42a6d3 !important;
  font-size: 24px;
  font-family: 'Londrina Solid';
  margin-top: 10px;
}

.headerRow h1 {
  color: var(--brand-dark-red);;
  font-size: 56px;
  font-family: 'Londrina Solid';
}

a {
  color: var(--brand-dark-red);
}
a:hover {
  color: var(--brand-color-red);
}

.mainContent {
  padding: 25px !important;
  color: var(--brand-warm-light-text);
  font-size: 20px !important;
  margin-bottom: 3rem;
  border-radius: 20px;
  border: 1px solid var(--brand-dark-red);
  /* padding-top: 1rem; */
}

.glasses {
  padding: 20px;
}

.sunouns {
  color: var(--brand-dark-red);
  text-decoration: none;
  display: inline-block;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition-duration: 1.5s;

}

.sunouns:hover {
  display: inline-block;
  color: var(--brand-sunrise-blue) !important;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}

.comingSoon {
  align-items: center;
  color: var(--brand-dark-red);;
  font-size: 90px;
  font-family: 'Londrina Solid';
}

.parentColor .color {
    fill: var(--brand-dark-red) !important;
  }
  
.parentColor:hover  .color  {
    fill: #42a6d3 !important;
  }

.color{
    fill: var(--brand-dark-red) !important;
  }

  .parentColor:hover {
    transition: 1.5s;
    -webkit-transform:rotate(180deg) !important;
    -moz-transform: rotate(180deg) !important;
    -ms-transform: rotate(180deg) !important;
    -o-transform: rotate(180deg) !important;
    transform: rotate(180deg) !important;
  }

  .parentColor {
    transition: 1.5s;
    -webkit-transform:rotate(0deg) !important;
    -moz-transform: rotate(0deg) !important;
    -ms-transform: rotate(0deg) !important;
    -o-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
  }


