
.flip {
  color: var(--brand-dark-red);
  text-decoration: none;
  display: inline-block;
  font-weight: strong;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition-duration: 1.5s;
}

.flip:hover {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}

@font-face {
  font-family: "forward";   /*Can be any text*/
  src: local("fff-forward.regular"),
    url("../../fonts/fff-forward/fff_forward_regular.ttf") format("truetype");
}
