.nounInfoRowBirthday {
  color: var(--brand-dark-red);
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-left: 5px;
}

.birthdayInfoContainer {
  color: var(--brand-warm-light-text);
  display: inline;
  width: 350px;
  height: 25px;
}

.birthdayIcon {
  margin-bottom: 4px;
  margin-right: 7px;
}
