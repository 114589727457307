.wrapper {

  border: 1px solid var(--brand-dark-red);
  background-color: black;
  color: var(--brand-dark-red);
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 0px 0px 14px;
  box-shadow: none;
}

.wrapper:hover {
  border: 1px solid var(--brand-dark-red);
  background-color: var(--brand-dark-red);
  color: black !important;
}

.whiteInfo {
  border: 1px solid var(--brand-dark-red);
  background-color: black;
}

.whiteInfo:hover {
  background-color: var(--brand-dark-red);
  color: black;
}

.coolInfo {
  border: 1px solid var(--brand-dark-red);
  background-color: black;
  color: var(--brand-dark-red);
}

.coolInfo:hover {
  background-color: var(--brand-dark-red);
  color: black;
}

.warmInfo {
  border: 1px solid var(--brand-dark-red);
  background-color: black;
  color: var(--brand-dark-red);
}

.warmInfo:hover {
  background-color: var(--brand-dark-red);
  color: black !important;
}

.whiteInfoSelected {
  border: 1px solid var(--brand-dark-red);
  background-color: black;
  color: var(--brand-dark-red);
}

.whiteInfoSelectedTop {
  background-color: #f4f4f8;
  border-top: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: var(--brand-warm-light-text);
}

.whiteInfoSelectedBottom {
  background-color: red;
  border-bottom: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: var(--brand-warm-light-text)
}

.dropdownActive {
  border: 1px solid var(--brand-dark-red);
  background-color: black;
  color: var(--brand-dark-red);
}
.coolInfoSelected {
  border: 1px solid var(--brand-dark-red);
  background-color: black;
  color: var(--brand-dark-red);
}

.warmInfoSelected {
  border: 1px solid var(--brand-dark-red);
  color: black;
  background-color: var(--brand-dark-red) ;
}


.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-right: 14px;
}

.dropdownBtnContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 18px;
  line-height: 20px;
}

.dropdownBtnContent:hover {
  color: black !important;
}

.arrowDown {
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
}

.arrowUp {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.button:hover {
  cursor: pointer;
  color: black;
}

.nounsNavLink,
.nounsNavLink:active,
.nounsNavLink:visited {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.3rem 0.3rem !important;
  color: var(--brand-black) !important;
}

.dropdownPrimaryText:hover {
  background-color: var(--brand-dark-red);
  color: black;
  cursor: pointer;
}

.desktopDropdown {
  background-color: inherit !important;
  border: 0px !important;
}

@media (max-width: 992px) {
  .wrapper,
  .button {
    height: 48px;
    font-size: 18px;
  }
}

@media (max-width: 330px) {
  .button {
    width: 70px;
  }
}

@media (max-width: 370px) {
  .button {
    width: 90px;
  }
}

@media (min-width: 400px) {
  .button {
    width: auto;
  }
}
