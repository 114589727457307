.wrapper {
  color: var(--brand-dark-red);
  font-family: 'Londrina Solid';
  font-size: 5rem;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 6rem;
    margin-left: 2rem;
  }
}
