.wrapper {
  display: inline-block;
  font-family: 'Londrina Solid';
  font-size: 120px;
  min-width: 580px;
}

.unrotate {
  display: inline-block;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition-duration: 1.5s;
}

.rotate {
  display: inline-block;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}

@font-face {
  font-family: "forward";
  src: local("fff-forward.regular"),
    url("../../fonts/fff-forward/fff_forward_regular.ttf") format("truetype");
}

@media (max-width: 600px) {
  .wrapper {
    min-width: 300px;
  }
}

@media (max-width: 992px) {
  .wrapper2 {
    display: inline !important;
  }

  .wrapper2 h1 {
    font-size: 150px !important;
  }

  .mainText {
    font-size: 40px;   
  }
  
  .numbers{
    font-size: 30px;
  }
}

