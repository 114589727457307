.enterBtnDesign {
  padding-right: 25px !important;
  padding-left: 25px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 40px !important;
  background-color: transparent !important;
  color: var(--brand-dark-red) !important;
  border: 2px solid var(--brand-dark-red);
  border-radius: 10px !important;
}

.noLine {
  text-decoration: none !important;
}

.enterBtnDesign:hover {
  background-color: var(--brand-dark-red) !important;
  color: var(--brand-su-black) !important;
}

.popUp {
  position: fixed !important;
  top: 30% !important;
  right: 7% !important;
  width: 25% !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: rgba(0, 0, 0, .85) !important;
  color: var(--brand-dark-red) !important;
  border: 6px solid rgb(75, 75, 75) !important;
  border-radius: 10px !important;
  z-index: 3 !important;
}


@media (max-width: 992px) {

  .enterBtnDesign {
    font-size: 30px !important;
  }

  .popUp {
    position: static !important;
    width: 100% !important;
    padding-right: 7% !important;
    padding-left: 7% !important;
    padding-top: 5% !important;
    padding-bottom: 5% !important;
    background-color: rgba(0, 0, 0, 1) !important;
    border: none !important;
    border-radius: 0px !important;
  }
}

@media (max-width: 450px) {
  .enterBtnDesign {
    font-size: 28px !important;
  }
}