
.headerRow h1 span {
  color: var(--brand-sunrise-blue);
}

.headerRow h1 {
  color: var(--brand-sunrise-blue);
  font-family: 'Londrina Solid';
}

.headerRow {
  color: var(--brand-sunrise-blue);
  font-size: 36px;
  font-family: 'Londrina Solid';
}

.headerRow a {
  text-decoration: none;
  color: var(--brand-dark-red);
}

.bullets h2 {
  align-self: center;
  color: var(--brand-dark-red);
  font-size: 34px;
  font-family: 'Londrina Solid';
}

.bullets p {
  align-self: center;
  color: var(--brand-warm-light-text);
  font-size: 18px;
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;
}

.zunsunz {
  color: var(--brand-dark-red);
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition-duration: 1.5s;

}
.zunsunz:hover {
  display: inline-block;
  color: var(--brand-sunrise-blue);
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}
