.nounWrapper {
  align-self: flex-end;
  width: 100%;
}

.nounContentCol {
  display: flex;
}

.enterActivityCol {
  padding-bottom: 0rem;
  color: var(--brand-dark-red) !important; 
}

.center {
  align-items: center;
  align-self: center;
  color: var(--brand-su-black);
}

.unrotate {
  align-items: center;  
  align-self: center;
  color: var(--brand-su-black);
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition-duration: 1.5s;
}

.rotate {
  align-items: center;  
  align-self: center;
  color: var(--brand-su-black);
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}

@media (max-width: 992px) {
  .center{
    padding-left: -3rem;
  }
  .center img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    }


  .enterActivityCol {
    align-items: center;
    align-self: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px !important;
    padding-right: 0rem !important;
    text-align: center !important;
    width: 80% !important;
    align-content: center !important;
}
}
@media (max-width: 568px) {
  .enterActivityCol {
    align-items: center;
    align-self: center;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .nounContentCol {
    padding: 0rem;
  }
}

.wrapper {
  display: inline-block;
}

.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 68px;
  margin-bottom: 10px;
}

@media (max-width: 450px) {
  .center img {
    margin-top: 50px !important;
    padding: -5rem;
    }

    .wrapper h1{
      font-size: 10px !important;
    }
  }

/* Animations*/

.element {
  transform: translate3d(0, -10%, 0);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeOutAnimation {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
   }
}