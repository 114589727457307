.headerWrapper h1 {
  color: var(--brand-warm-light-text);
  background-color: var(--brand-su-black);
  font-family: 'Londrina Solid';
  font-size: 4rem;
}

.headerWrapper p {
  color: var(--brand-warm-light-text);
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;
  margin: 3rem 0;
}

.accordionItem {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;
  font-size: 1.2rem !important;
}

@media (max-width: 992px) {
  .headerWrapper {
    color: var(--brand-dark-red);
    padding: 1rem;
  }
}

.accordionItem {
  color: var(--brand-warm-light-text);
  margin-bottom: 2.5rem;
  border: none;
  text-decoration: none;
}

.aboutText {
  text-decoration: none;
  color: var(--brand-warm-light-text);
  margin: 0px !important;
}

.accordionItem,
.headerWrapper {
  background-color: var(--brand-su-black);
  color: var(--brand-warm-light-text);
  font-size: 1.3rem;
}

.accordionHeader button {
  border: none;
  background-color: var(--brand-su-black);
  font-family: 'Londrina Solid';
  font-size: 2.5rem !important;
  cursor: pointer;
  line-height: normal;
  padding-top: 0;
  padding-bottom: 0;
}

.accordionHeader button.collapsed {
  color: var(--brand-dark-red);
  background-color: var(--brand-su-black);
  box-shadow: none;
}

.accordionHeader button:not(.collapsed) {
  color: var(--brand-warm-light-text);
  background-color: transparent;
  box-shadow: none !important;
}

/* override bootstrap color */
.accordionHeader button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23D63C5E'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordionHeader button:hover {
  color: var(--brand-dark-red) !important;
  box-shadow: none !important;
}

.sunouns {
  color: var(--brand-dark-red);
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition-duration: 1.5s;

}

.sunouns:hover {
  display: inline-block;
  color: var(--brand-sunrise-blue);
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}

@media (max-width: 992px) {
  .accordionHeader button {
    color: var(--brand-warm-light-text);
    padding-left: 20px;
  }
}
