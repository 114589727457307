.wrapper {
  display: inline-block;
}

.wrapper h1 {
  color: var(--brand-dark-red);
  font-family: 'Londrina Solid';
  font-size: 50px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.su {
  font-size: 110px;
  color: var(--brand-sunrise-blue);
}

.noun {
  font-size: 110px;
}

.number {
  font-size: 45px;
  font-family: forward;
}

.flip {
  transform: rotate(0deg);
  transition-duration: 1.5s;
}

.flip:hover {
  display: inline-block;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transition-duration: 1.5s;
}

@media (max-width: 992px) {
  .wrapper h1 {
    font-size: 56px;
  }
}

@media (max-width: 600px){

  .su {
    font-size: 70px;
    color: var(--brand-sunrise-blue);
  }
  
  .noun {
    font-size: 70px;
  }
  
  .number {
    font-size: 28px;
    font-family: forward;
  }
}

@font-face {
  font-family: "forward";   /*Can be any text*/
  src: local("fff-forward.regular"),
    url("../../fonts/fff-forward/fff_forward_regular.ttf") format("truetype");
}